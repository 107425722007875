import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function MenAccessoriesPage() {
  return (
    <CategoryWrapper
      seoTitle="Accessories"
      gender="herre"
      category="accessories"
    />
  )
}

export default MenAccessoriesPage
